<template>
  <action-edit
    :action-url="`feed-connectors/nod/manufacturers/${item.id}`"
    :item="item"
    button-label="Editeaza Legatura de brand"
  >

    <template v-slot:fields="{form, errors}">

      <brand-selector v-model="form.cit_brand_id"/>
      <error-messages :errors="errors.cit_brand_id"/>

    </template>

  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import BrandSelector from '@/components/autocompletes/BrandSelector'

export default {
  components: {
    BrandSelector,
    ActionEdit,
    ErrorMessages
  },
  props: {
    item: {
      required: true
    }
  }
}
</script>
